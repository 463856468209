import Select from 'react-select'
import React, { ReactNode } from 'react'
import { LocationType } from '@marketing-milk/interfaces'
import { stringOrNumberArraysEqual } from '@marketing-milk/frontend'

export interface LocationTargetProps {
  locationTypes: LocationType[] | undefined
  updateLocationTargetType: (types: [LocationType.Home, LocationType.Recent]) => void
}

export const targetOptions: Array<{ label: ReactNode; value: LocationType[] }> = [
  {
    label: 'People Living in or recently in this location',
    value: [LocationType.Home, LocationType.Recent],
  },
]

export function LocationTarget(props: LocationTargetProps) {
  const { locationTypes, updateLocationTargetType } = props
  const targetValue = locationTypes
    ? targetOptions.find(x => stringOrNumberArraysEqual(x.value, locationTypes ?? []))
    : null

  function selectTarget(option: {
    value: [LocationType.Home, LocationType.Recent]
    label: string
  }) {
    updateLocationTargetType(option.value)
  }

  return (
    <div className="mt-4">
      <p className="text-bold">Targeting</p>
      <hr />
      <div>
        <Select value={targetValue} options={targetOptions} onChange={selectTarget as any} />
      </div>
    </div>
  )
}
