import React from 'react'
import { useBaseAudience } from './use-base-audience'
import { StepProps } from '../../../../../components/Stepper'
import { Demographics, Locations, ConnectedAudiences } from './steps'

export const getBaseWizardSteps = (props: ReturnType<typeof useBaseAudience>): StepProps[] => {
  const locationsStepComplete =
    // user has selected a lat/long (pin drop), state (region), or country
    // must be at least one included location (number of EXCLUDED doesnt matter)
    (props.targetSpec.geo_locations?.custom_locations?.length !== 0 ||
      props.targetSpec.geo_locations?.countries?.length !== 0 ||
      props.targetSpec.geo_locations?.regions?.length !== 0) &&
    props.targetSpec.genders?.length !== 0 &&
    !!props.targetSpec.geo_locations?.location_types

  return [
    {
      title: 'Locations',
      completed: locationsStepComplete,
      canContinue: locationsStepComplete,
      children: <Locations {...props} />,
    },
    {
      title: 'Demographics',
      completed: true,
      canContinue: true,
      children: <Demographics setTargetSpec={props.setTargetState} targetSpec={props.targetSpec} />,
    },
    {
      title: 'Connected Audiences',
      completed: true,
      canContinue: true,
      children: (
        <ConnectedAudiences
          customAudiences={props.customAudiences ?? []}
          excludedAudiences={props.getExcludedCustomAudiences()}
          includedAudiences={props.getIncludedCustomAudiences()}
          onAddExcludedAudience={props.addExcludedAudience}
          onAddIncludedAudience={props.addIncludedAudience}
          onRemoveExcludedAudience={props.removeExcludedAudience}
          onRemoveIncludedAudience={props.removeIncludedAudience}
          targetSpec={props.targetSpec}
        />
      ),
    },
  ]
}
